<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="show"
      max-width="900px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      content-class="rounded-xl"
    >
      <v-card :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex justify-space-between">
          <div class="text-h6">Ledger</div>

          <v-btn icon @click="modalClosed">
            <v-icon size="22">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text class="pa-0">
          <v-data-table
            height="460px"
            fixed-header
            :items-per-page="page_count"
            :server-items-length="page_count"
            :loading="loading"
            loading-text="Please wait.... Loading"
            :headers="headers"
            :items="items"
            hide-default-footer
            mobile-breakpoint
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ $utils.getDateFormat(item.datetime) }}
            </template>

            <template v-slot:[`item.case`]="{ item }">
              <div v-if="item.case">
                {{ item.case.case_id }}
              </div>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.credit`]="{ item }">
              <div
                v-if="item.credit_amount"
                class="green--text font-weight-bold"
              >
                +{{ item.credit_amount }}
              </div>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.debit`]="{ item }">
              <div v-if="item.debit_amount" class="red--text font-weight-bold">
                -{{ item.debit_amount }}
              </div>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.balance`]="{ item }">
              <div v-if="item.balance_amount" class="font-weight-bold">
                {{ item.balance_amount }}
              </div>
              <div v-else>0</div>
            </template>
          </v-data-table>

          <v-divider />

          <div class="my-4 d-flex justify-center" v-if="page_number < page_total">
            <v-btn small color="primary" @click="loadMore">Load More</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { ledgerTable } from "@/headers";
export default {
  data() {
    return {
      loading: false,
      items: [],
      page_total: 1,
      page_number: 1,
      total_count: 0,
      page_count: 10,
    };
  },
  computed: {
    ...mapGetters({
      dialog: "ledger/dialog",
    }),

    headers() {
      return ledgerTable;
    },

    show: {
      get() {
        return this.dialog.show;
      },
      set() {},
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },

    page_number() {
      this.getLedgerList();
    },
  },
  methods: {
    ...mapActions({
      toggleDialog: "ledger/toggleDialog",
    }),

    async openModal() {
      this.getLedgerList();
    },

    modalClosed() {
      this.items = [];
      this.page_total = 1;
      this.page_number = 1;
      this.total_count = 0;
      this.page_count = 10;
      this.toggleDialog({ show: false });
    },

    getLedgerList() {
      this.loading = true;

      const onSuccess = (res) => {
        this.items = [...this.items, ...res.data.data];

        const page_info = res.data.page_info;
        this.page_total = page_info.page_total;
        this.page_number = page_info.page_number;
        this.total_count = page_info.total_count;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        user_id: this.dialog.user_id,
      };

      return this.$request(this.$keys.GET, this.$urls.ledger.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },

    loadMore() {
      this.page_number += 1;
      this.getLedgerList();
    },
  },
};
</script>
