<template>
  <div>
    <!-- data table -->
    <v-data-table
      class="elevation-3 rounded-t-0"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:item.sn="{ index }">
        {{ index + 1 }}
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div v-if="item.name">
          {{ item.name }}
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.contact`]="{ item }">
        <div v-if="item.user.mobile || item.user.email || item.full_address">
          <div v-if="item.user.mobile">
            {{ item.user.mobile_display }}
          </div>
          <div v-if="item.user.email">
            {{ item.user.email }}
          </div>
          <div v-if="item.full_address">
            {{ item.full_address }}
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <v-chip small v-if="item.role">
          {{ item.role_display }}
        </v-chip>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.balance`]="{ item }">
        <div v-if="item.user.ledger_balance" class="font-weight-bold">
          {{ item.user.ledger_balance }}
        </div>
        <div v-else>0</div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="py-4 d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize"
            height="32px"
            @click="addNote(item)"
          >
            Add Note
          </v-btn>
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize"
            height="32px"
            @click="openLedger(item)"
          >
            View Ledger
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- pagination -->
    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <LedgerDialog />
    <AddNoteDialog />
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import LedgerDialog from "@/components/Ledger/DataTableDialog";
import AddNoteDialog from "@/components/Ledger/AddNoteDialog";
import { accountsLedgerTable } from "@/headers";
export default {
  components: { LedgerDialog, AddNoteDialog },
  data() {
    return {
      items: [],
      loading: false,

      page_total: 1,
      page_number: 1,
      total_count: 0,
      page_count: 10,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
    };
  },

  computed: {
    ...mapGetters({
      activeTab: "ledger/activeTab",
      search: "ledger/search",
    }),

    headers() {
      return accountsLedgerTable[this.activeTab.key];
    },

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },

  watch: {
    page_count() {
      this.getListData();
    },

    page_number() {
      this.getListData();
    },

    activeTab: {
      handler() {
        this.getListData();
      },
      deep: true,
    },

    search() {
      this.debouncedReloadData();
    },
  },

  mounted() {
    this.getListData();
  },

  methods: {
    ...mapActions({
      toggleLedgerDialog: "ledger/toggleDialog",
      toggleAddNoteDialog: "ledger/toggleAddNoteDialog",
    }),

    debouncedReloadData: _.debounce(function () {
      this.getListData();
    }, 1000),

    getListData() {
      let url;
      switch (this.activeTab.key) {
        case "fe_ir_ledger":
          url = this.$urls.fe_ir.list;
          break;
        case "university_ledger":
          url = this.$urls.university.list;
          break;
        default:
          return;
      }

      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.items = res.data.data;

        const page_info = res.data.page_info;
        this.page_total = page_info.page_total;
        this.page_number = page_info.page_number;
        this.total_count = page_info.total_count;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        search: this.search,
      };
      return this.$request(this.$keys.GET, url, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },

    openLedger(item) {
      this.toggleLedgerDialog({ show: true, user_id: item.user.id });
    },

    addNote(item) {
      this.toggleAddNoteDialog({ show: true, user_id: item.user.id });
    },
  },
};
</script>