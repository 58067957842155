<template>
  <div>
    <v-dialog
      persistent
      v-model="show"
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      content-class="rounded-xl"
    >
      <v-card height="auto" :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex justify-space-between">
          <div class="text-h6">Add Note</div>

          <v-btn icon @click="modalClosed">
            <v-icon size="22">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text class="">
          <v-form class="pt-5" lazy-validation ref="form">
            <div class="mb-4 d-flex align-center" style="gap: 20px">
              <v-btn-toggle
                v-model="form.transaction_type"
                :color="form.transaction_type === 'credit' ? 'green' : 'red'"
              >
                <v-btn value="credit" class="text-capitalize"> Credit </v-btn>
                <v-btn value="debit" class="text-capitalize"> Debit </v-btn>
              </v-btn-toggle>

              <div style="width: 100%">
                <v-text-field
                  autofocus
                  placeholder="Amount"
                  v-model="form.amount"
                  :rules="[$rules.validateNegativeNumber]"
                  @keydown="$rules.blockInvalidChar"
                  @input="validateNegativeNumber('amount')"
                  height="48px"
                  type="number"
                  outlined
                  dense
                  hide-details
                  hide-spin-buttons
                ></v-text-field>
              </div>
            </div>
            <v-text-field
              placeholder="Remark"
              v-model="form.remark"
              height="48px"
              type="text"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            class="text-capitalize"
            @click="submit"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,

      form: {
        transaction_type: "credit",
        amount: 0,
        remark: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      dialog: "ledger/addNoteDialog",
    }),

    show: {
      get() {
        return this.dialog.show;
      },
      set() {},
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      toggleDialog: "ledger/toggleAddNoteDialog",
    }),

    async openModal() {},

    modalClosed() {
      this.form = {
        transaction_type: "credit",
        amount: 0,
        remark: "",
      };
      this.toggleDialog({ show: false });
    },

    validateNegativeNumber(field) {
      let value = this.form[field];
      value = Number(value);

      if (value < 0) {
        this.form[field] = Number(0);
      } else this.form[field] = value;
    },

    submit() {
      this.loading = true;
      const form = this.form;

      if (form.amount <= 0) {
        this.showSnackbar({
          text: "Incorrect data",
          color: "error",
        });
        this.loading = false;
        return;
      }

      const data = {
        user: this.dialog.user_id,
        ...form,
      };

      const onSuccess = () => {
        this.modalClosed();
      };

      const onFinally = () => {
        this.loading = false;
      };

      return this.$request(this.$keys.POST, this.$urls.ledger.create, {
        data,
        onSuccess,
        onFinally,
      });
    },
  },
};
</script>
