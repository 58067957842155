export default [
  {
    label: `FE/IR Ledger`,
    key: "fe_ir_ledger",
    value: 0,
  },
  {
    label: `University Ledger`,
    key: "university_ledger",
    value: 0,
  },
];
